<template>
  <div class="page-box" :style="{ backgroundImage: `url(${bgImg})` }">
    <div class="content f fv fc">
      <div class="content-detail">
        <img :src="summary_image" alt="" class="summary-image f" />
      </div>
      <div class="pay-area">
        <div class="css-type2">
          <div class="select-item f fc wechat" v-debounce:wechat="createOrder" v-if="wechat">
            <div class="pay-way f fc">
              <img src="https://img-fe.tengzhihh.com/image/951c7d88ff5302-47x47.png" class="icon" />微信支付
            </div>
          </div>
          <div class="select-item f fc alipay" v-debounce:alipay="createOrder" v-if="alipay">
            <div class="pay-way f fc">
              <img src="https://img-fe.tengzhihh.com/image/134b410b4a030a-47x47.png" class="icon" />支付宝
            </div>
          </div>
        </div>
      </div>

      <div class="contact-area f fc fvc" @click="kefu">联系客服</div>
    </div>
    <Dialog ref="dialog" />
  </div>
</template>

<script>
import apiPath from '@/utils/apiPath';
import PayWays from '@/components/PayWays/PayWays';
import Dialog from '@/components/Dialog/Dialog.vue';
import tools from '@/utils/tools';
import { createVipOrder } from '@/api/shop';
import { setStore } from '@/utils/localStore';

export default {
  components: { PayWays, Dialog },
  data() {
    return {
      bgImg: '',
      goods_id: 0,
      summary_image: 'https://img-fe.tengzhihh.com/image/a7db054c7cd45120afad130a08918d48-672x888.png',
      is_vip: true,
    };
  },
  computed: {
    alipay() {
      return !tools.isWeChat();
    },
    wechat() {
      return tools.isWeChat();
    },
    return_url: function () {
      const mainUrl = location.origin;
      return `${mainUrl}/shop/paySuccess`;
    },
  },
  mounted() {
    this.goods_id = this.$route.query.goods_id;
    this.getWelfare();
  },
  methods: {
    kefu() {
      this.$utils.kefuDialog(this.$refs.dialog);
    },
    sliceArray(arr, perLength, result = []) {
      let tmp = JSON.parse(JSON.stringify(arr));
      result.push(tmp.splice(0, perLength));
      if (tmp.length > perLength) {
        this.sliceArray(tmp, perLength, result);
      } else {
        result.push(tmp);
      }
      return result;
    },
    getWelfare() {
      this.$http.get(`${apiPath.goodsDetail}?goods_id=${this.goods_id}&ver=1.1`).then(res => {
        this.is_vip = res.data.user.is_vip;
      });
    },
    async createOrder(payment_mode) {
      const params = {
        goods_id: this.goods_id,
        payment_mode,
        platform: payment_mode === 'wechat' ? 'wechat' : 'web',
        return_url: this.return_url,
        ver: '1.1',
      };
      const res = await createVipOrder(params);
      if (res.code !== 0) return;
      this.orderId = res.data.order_center_id;
      const jumpUrl = this.spliceUrl(payment_mode, res.data);
      jumpUrl && (location.href = jumpUrl);
    },
    spliceUrl(payment_mode, response) {
      setStore({
        name: 'routePrevious',
        content: this.$route.path,
      });
      if (payment_mode === 'alipay') {
        return `https://${response.default}/pay?order_id=${response.order_center_id}&channel_id=${response.ali_pay_channel}`;
      } else if (payment_mode === 'wechat') {
        if (this.$tools.isWeChat()) {
          return `https://${response.wechatv3_official}/pay?order_id=${response.order_center_id}&channel_id=${response.wechat_office_pay_channel}`;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.page-box {
  width: 100%;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #313131;
  .content {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    padding-bottom: 60px;
    .content-detail {
      padding-top: 19px;
      .summary-image {
        width: 336px;
        height: 444px;
      }
    }
    .pay-area {
      margin: 30px 0;
      .css-type2 {
        .select-item {
          width: 329px;
          height: 43px;
          background: #6cc142;
          border-radius: 21px;
          color: #fff;
          margin-bottom: 11px;
          font-size: 15px;
          align-items: center;
          justify-content: center;
        }
        .icon {
          width: 20px;
          height: 20px;
          margin-right: 8px;
        }
        .wechat {
          font-weight: 600;
          background-color: #6cc142;
        }
        .alipay {
          font-weight: 600;
          background-color: #01abf0;
        }
        .virtual-coin {
          background: linear-gradient(10deg, #ffe79f 0%, #ffda77 100%);
          color: #804e00;
          font-weight: 600;
        }
        .select-item:last-child {
          margin: 0;
        }

        .virtual {
          color: #e69d17;
        }
        .virtual-amount {
          font-size: 15px;
          color: #e69d17;
        }
        .right-arrow {
          font-size: 14px;
          font-weight: bold;
          color: #b5b5b5;
        }
      }
    }
    .contact-area {
      width: 375px;
      height: 55px;
      background: #434343;
      font-size: 18px;
      font-weight: 800;
      color: #ffeab5;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
}
</style>
