<template>
  <div v-if="pageStep === 0" class="page-box f fc fvc loading-tip">跳转中...</div>
  <Slogan v-else-if="pageStep === 1" />
  <LoginWay v-else-if="pageStep === 2" @setValue="setValue" />
  <PhoneLogin v-else-if="pageStep === 3" @setValue="setValue" :sign="sign" :serialId="serialId" />
  <LoginSuccess v-else-if="pageStep === 4" @setValue="setValue" :sign="sign" :serialId="serialId" />
</template>

<script>
import apiPath from '@/utils/apiPath';
import { mapGetters } from 'vuex';
import Slogan from './comp/Slogan.vue';
import LoginWay from './comp/LoginWay.vue';
import PhoneLogin from './comp/PhoneLogin.vue';
import LoginSuccess from './comp/LoginSuccess.vue';

export default {
  components: {
    Slogan,
    LoginWay,
    PhoneLogin,
    LoginSuccess,
  },
  data() {
    return {
      pageStep: 2, // 0跳转中 1slogan 2登录方式选择 3手机登录 4登录成功
      code: '',
      from: '',
      timeout: null,
      serialId: '',
      sign: '',
    };
  },
  computed: {
    ...mapGetters(['userAccessToken']),
  },
  created() {
    this.pageStep = this.$route.query.page_step;
  },

  mounted() {
    const code = this.$tools.getQueryFromUrl('code');
    const from = this.$route.query.from;
    this.serialId = this.$route?.query?.serial_id;
    this.sign = this.$route?.query?.sign;
    this.handleCode(code);
    this.handleFrom(from);

    if (this.userAccessToken) {
      this.pageStep = 2;
      if (from) {
        this.$router.replace(from);
      } else {
        this.pageStep = 4;
      }
    } else {
      this.pageStep = 1;
      this.timeout = setTimeout(() => {
        [0, 1].includes(this.pageStep) && (this.pageStep = 2);
      }, 1000);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  watch: {
    code: function (n) {
      this.pageStep = 2;
      this.getOpenId(n);
    },
  },
  methods: {
    setValue(k, v) {
      this[k] = v;
    },
    handleFrom(from) {
      from && (this.from = decodeURIComponent(from));
    },
    handleCode(code) {
      if (Array.isArray(code)) {
        this.code = code.pop();
      } else {
        this.code = code;
      }
    },
    getOpenId(code) {
      this.$http
        .get(apiPath.getOpenId, {
          code,
        })
        .then((res) => {
          this.$track('wechat_log_success');
          this.$store.commit('setUserOpenId', res.data.openid);
          if (res.data.access_token) {
            this.$store.commit('setUserAccessToken', res.data.access_token);
            if (this.from) {
              this.$router.replace(this.from);
            } else {
              this.pageStep = 4;
            }
          } else {
            // 新用户，跳转到手机登录
            this.pageStep = 3;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.loading-tip {
  font-size: 13px;
  font-weight: 500;
  color: #838383;
  line-height: 13px;
}
</style>
