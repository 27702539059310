<template>
  <div class="goods-list-box">
    <van-tabs v-model:active="active" background="#e9e7e6" animated sticky swipeable @change="changeNav">
      <van-tab v-for="item in nav" :title="item.name" title-class="item" :key="item.id">
        <div class="list-box">
          <goods-list :list="list"></goods-list>
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import GoodsList from '@/components/Goods/GoodsList';
import apiPath from '@/utils/apiPath';
export default {
  components: {
    GoodsList,
  },
  data() {
    return {
      nav: [
        {
          name: '精选商品',
          id: '1',
        },
        {
          name: '生活品质',
          id: '2',
        },
        {
          name: '虚拟服务',
          id: '3',
        },
      ],
      active: 0,
      list: [],
    };
  },
  mounted() {
    const v = sessionStorage.getItem('shopListActive');
    if (v !== void 0) {
      this.active = Number(v);
    }
    this.changeNav(this.active);
  },
  beforeDestroy() {
    sessionStorage.setItem('shopListActive', this.active);
  },
  methods: {
    changeNav(item) {
      if (+item === 1) {
        this.getList({ category: 'entity' });
      } else if (+item === 2) {
        this.getList({ category: 'virtual' });
      } else {
        this.getList({ is_recommend: 1 });
      }
    },
    getList(e) {
      this.$http
        .get(
          `${apiPath.goods}?page=1&size=200&ver=1.1${e.category ? '&category=' + e.category : ''}${
            e.is_recommend ? '&is_recommend=' + e.is_recommend : ''
          }`
        )
        .then(res => {
          this.list = res.data.list;
        });
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list-box {
  background-color: #e9e7e6;
  min-height: calc(var(--vh) * 100 - 50px);
  .list-box {
    min-height: calc(var(--vh) * 100 - 150px);
  }
}
</style>
<style lang="less">
.goods-list-box {
  .van-tabs__nav {
    padding: 0 20px;
  }
  .van-tab {
    padding: 0 !important;
    width: fit-content;
    flex: unset !important;
    font-size: 13px;
    font-weight: 500;
    color: #656565;
    margin-right: 17px;
  }

  .van-tab--active {
    font-size: 17px;
    font-weight: bold;
    color: #252424;
  }
  .van-tabs__line {
    display: none;
  }
}
</style>
