import CryptoJS from 'crypto-js';

function keyIv() {
    let key = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');
    let iv = CryptoJS.enc.Utf8.parse('abcdefghijklmnop');
    return {key, iv};
}

function Decrypt(text) {
    let {key, iv} = keyIv();
    let decrypt = CryptoJS.AES.decrypt(text, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    let decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

    return JSON.parse(decryptedStr.toString());
}

function Encrypt(text) {
    let {key, iv} = keyIv();
    let srcs = JSON.stringify(text);

    // aes加密
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // base64加密
    return encrypted.ciphertext.toString(CryptoJS.enc.Base64);
}

export {Decrypt, Encrypt};
